import http from "@/utils/http";

// 发送登录验证码
export const sendLoginCode = (params) => http.get('/customer/send_verification_code/', params);
// 发送修改手机号验证码
export const sendModifyPasswordCode = (params) => http.get('/customer/send_verification_code/', params);
// 获取图片验证码
export const getPictureCode = () => http.get('/customer/picture_code/');
// 验证码登录
export const loginByCode = (params) => http.get('/customer/login/', params);
// 密码登录
export const loginByPwd = (params) => http.get('/customer/login/', params);
// 密码修改
export const modifyPassword = (params) => http.put('/login/modifyPassword/', params);

// 查询用户信息
export const customerInfo = (params) => http.get('/customer/info/');

// 更新用户信息
export const updatecustomerInfo = (params) => http.get('/customer/');



