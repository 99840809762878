<template>
  <div>
    <van-nav-bar title="个人中心"/>
    <div style="padding: 10px; height: 95%;flex-direction: column;">

      <van-row style="text-align: center;" v-if="false">
        <van-col span="24" style="color: #333333;font-size: 16px;">{{ userInfo.name }}<span style="font-size: 14px;" v-if="false">({{userGender}})</span></van-col>
        <van-col span="24" style="padding-top: 10px;">{{ userPhone }}</van-col>
      </van-row>
      <van-cell-group inset style="margin-top: 10px;height: 80%">
        <van-cell title="单位" :value="userInfo.workplace" v-if="false"/>
        <van-cell title="项目组" :value="userInfo.project" v-if="false"/>
        <van-cell title="地区" :value="userInfo.area" v-if="false"/>
        <van-cell title="推荐人" :value="userInfo.referrer" v-if="false"/>
        <van-cell title="邮箱" :value="userInfo.mail" v-if="false"/>
        <van-cell title="通讯地址" :value="userInfo.postal_address" v-if="false"/>
        <van-cell title="姓名" :value="userInfo.name"/>
        <van-cell title="手机号" :value="userPhone"/>
        <van-cell title="风险等级" :value="riskLevel"/>
        <van-cell title="已购买产品" value="查看" is-link @click="toProduct" v-if="false"/>
      </van-cell-group>
      <van-row style="text-align: center;margin-top: auto;height: 60px;line-height: 60px;margin-bottom: 10px;">
        <van-col span="24">
          <van-button block round type="danger" @click="logout">退出登录</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { customerInfo} from "@/api";
export default {
  name: "Mine",
  data() {
    return {
      userInfo: {}
      // userInfo: qs.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    store.commit('toggleTabbarShow', true);
    this.userInfoData();
  },
  methods: {
    logout() {
      this.$store.commit('logout')
    },
    toProduct () {
      this.$router.push("/product")
    },
    userInfoData () {
      customerInfo()
          .then(res => {
            console.log('userinfo-->', res.data[0]);
            if (res){
              if (res.code === 1000) {
                this.userInfo = res.data[0];
                localStorage.setItem('userInfo',this.userInfo);
              } else {
                this.errMsg = res.msg;
              }
            }
          })
    }
  },
  computed: {
    riskLevel() {
      const type_level = typeof this.userInfo.risk_level
      console.log(type_level);
      if (type_level === 'string') {
        this.userInfo.risk_level = Number(this.userInfo.risk_level)
      }
      switch (this.userInfo.risk_level) {
        case 1:
          return 'R1'
        case 2:
          return 'R2'
        case 3:
          return 'R3'
        case 4:
          return 'R4'
        case 5:
          return 'R5'
        default:
          return ''
      }
    },
    userGender () {
      switch (this.userInfo.gender) {
        case '1':
          return '女'
        case '2':
          return '男'
        default:
          return '未知'
      }
    },
    userPhone () {
      const phone = this.userInfo.phone
      console.log('手机号：', phone);
      if (phone)
      return phone.substr(0,3)+ '****' + phone.substr(phone.length-4,phone.length)
    }
  }
}
</script>

<style scoped>

</style>
